<template>
  <header class="theader">
    <div class="tlogo" @click="navigate('/talent')">
      <img src="@/assets/brand/goflurry.png" alt="Company Logo">
    </div>
    <nav class="tnavigation" v-if="showHeader">
      <ul>
        <li @click="navigate('/talent')">Talent</li>
        <li @click="navigate('/tsheet')">T-Sheet</li>
      </ul>
    </nav>
    <CHeaderNav v-if="showHeader">
      <AppHeaderDropdownAccnt />
    </CHeaderNav>
  </header>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { mapGetters } from 'vuex';

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
  },
  computed: {
    ...mapGetters('login', {
      isLoggedIn: 'isLoggedIn',
    }),
    showHeader(){ // STATE is not persisting, it reset on reload of page.
      return this.isLoggedIn || localStorage.getItem('login')
    }

  },
  methods:{
    navigate(path){
      this.$router.push(path)
    }

  }
}
</script>

<style scoped>
.theader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #efefef;
  /* position: fixed */
}

.tlogo{
  font-size: 28px;
  padding: 0px 0px 0px 20px;
  cursor: pointer;
}
.tlogo img {
  height: 40px;
}

.tnavigation ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tnavigation ul li {
  margin: 10px 60px 0 0px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}
</style>
