import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/talent',
    children: [
      {
        path: '/talent',
        name: 'Talents',
        component: () => import('@/views/talent/Index.vue'),
      },
      {
        path: '/talent/detail/:id',
        name: 'Talent Details',
        component: () => import('@/views/talent/Detail.vue'),
      },
      {
        path: '/tsheet',
        name: 'Tsheets',
        component: () => import('@/views/tsheet/Index.vue'),
      },
      {
        path: '/tsheet/detail/:id',
        name: 'Tsheet Detail',
        component: () => import('@/views/tsheet/Detail.vue'),
      },
      {
        path: '/tsheet/tracking/:id',
        name: 'Tracking',
        component: () => import('@/views/tsheet/Tracking.vue'),
      },
      {
        path: '/tsheet/integration/:id',
        name: 'Integration',
        component: () => import('@/views/tsheet/Integration.vue'),
      },
      {
        path: '/tsheet/create/1',
        name: 'T-Sheet Create 1',
        component: () => import('@/views/tsheet/NewSheet1.vue'),
      },
      {
        path: '/tsheet/create/3',
        name: 'T-Sheet Create 3',
        component: () => import('@/views/tsheet/NewSheet3.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      {
        path: '/v/:id',
        name: 'T-Sheet',
        component: () => import('@/views/pages/TsheetDetail.vue'),
      },
      {
        path: '/t/:id',
        name: 'T-Sheet Tracking',
        component: () => import('@/views/tsheet/Tracking.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
