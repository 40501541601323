const state = {
  isLoggedIn: false,
  guestLoggedIn: false
};
  
const getters = {
  isLoggedIn: state => state.isLoggedIn,
  guestLoggedIn: state => state.guestLoggedIn
};
  
const mutations = {
  setLoggedIn(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  setguestLoggedIn(state, guestLoggedIn) {
    state.guestLoggedIn = guestLoggedIn;
  }
};
  
const actions = {
  login({ commit }) {
    commit('setLoggedIn', true);
  },
  logout({ commit }) {
    commit('setLoggedIn', false);
  },
  guestlogin({ commit }) {
    commit('setguestLoggedIn', true);
  },
  guestlogout({ commit }) {
    commit('setguestLoggedIn', false);
  },
};
  
const loginModule = {
  namespaced: true, // Add this line to enable namespacing
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
  
export default loginModule;
  