import Vuex from 'vuex'
import loginModule from './login';

// const store =  createStore({
//   state: {
//     sidebarVisible: '',
//     sidebarUnfoldable: false,
//     login: false,
//     talents: [],
//     newTsheet: {},
//   },
//   mutations: {
//     toggleSidebar(state) {
//       state.sidebarVisible = !state.sidebarVisible
//     },
//     toggleUnfoldable(state) {
//       state.sidebarUnfoldable = !state.sidebarUnfoldable
//     },
//     updateSidebarVisible(state, payload) {
//       state.sidebarVisible = payload.value
//     },
//     setData(state, { variable, value }) {
//       console.log("setting data", variable, value)
//       state[variable] = value;
//     }
//   },
//   actions: {
//     setData({ commit }, { variable, value }) {
//       console.log("setting data actions", variable, value)
//       commit('setData', { variable, value });
//     }
//   },
//   getters: {
//     getData: (state) => (variable) => {
//       return state[variable];
//     },
//     getLogin:
//   }
// })

const store = new Vuex.Store({
  modules: {
    login: loginModule
  }
});

export default store;
