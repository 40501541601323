import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import Notifications from '@kyvg/vue3-notification'
import './global-styles.scss';

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Notifications)
app.provide('icons', icons)
app.component('CIcon', CIcon)

app.mount('#app')

let publicRoute = ['/login', '/register', '/v/:id', '/t/:id'] // Update the array to include the pattern

router.beforeEach((to, from, next) => {
  // console.log('Router', to)
  if (localStorage.getItem('login')) {
    // check login
    if (publicRoute.includes(to.path)) {
      //if already logged in //ALLOW LOGGEDIN USER TO VIEW `v/:id` - `v/:id` will pass from
      next({
        path: '/talent',
      })
    } else {
      // TODO remove this after adding permission on each page
      next()
    }
  } else {
    // if logged out
    // console.log('Router not logged in 2', to)
    if (publicRoute.some(route => to.path.match(new RegExp(`^${route.replace(/:[^/]+/, '[^/]+')}$`)))) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      }) // make sure to always call next()!
    }
  }
})
