<template>
  <div>
    <!-- <AppSidebar /> -->
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader/>
      <div class="body flex-grow-1 px-3 tbcustom">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
      <!-- <AppFooter /> -->
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppHeader from '@/components/AppHeader.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    CContainer,
  },
}

</script>

<style scoped>
.tbcustom {
  background-color: #ffffff;
  padding: 20px 0 0 10px;
}
</style>
